import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "register-view relative min-h-screen bg-[#232120] lg:flex lg:items-start lg:justify-center" }
const _hoisted_2 = { class: "flex flex-col pt-8 lg:min-h-screen lg:w-[580px] lg:min-w-[580px] lg:items-center lg:justify-center" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "mx-auto text-center" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "mx-auto text-center text-[14px] text-white" }

import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useApiOp } from "@team-uep/vue-api-op";
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile";

import type { APISessionCurrentResponse } from "@/types/api-extended.interfaces";
import type { RegisterTypes } from "@/components/register/useRegister";

import { useUserStore } from "@/store/user";
import { useTrackingStore } from "@/store/tracking";
import { updateStoreAfterSessionCurrent } from "@/helpers/store";
import RegisterTypeForm from "@/components/register/RegisterTypeForm.vue";
import RegisterCover from "@/components/register/RegisterCover.vue";
import RegisterInfoModal from "@/components/register/RegisterInfoModal.vue";

import { ValidStateModel } from "@team-uep/n-cta-loader";
import KrupsCta from "@/components/ui/KrupsCta.vue";

type RegisterPayload = {
  iCivilId: number;
  sFirstName: string;
  sLastName: string;
  sEmail: string;
  sZipcode: string;
  sMobile: string;
  oResponses: {
    sQuestion_101: string;
    dBirthDate_1?: string;
  };
  oOptins?: {
    iOptin_2?: number;
    iOptin_4?: number;
  };
};


export default /*@__PURE__*/_defineComponent({
  __name: 'Register',
  setup(__props) {

/* eslint-disable camelcase */
const router = useRouter();
const route = useRoute();
const api = useApiOp();
const userStore = useUserStore();
const trackingStore = useTrackingStore();

const isInForm = ref(false);
const isDisplayingRelog = ref(false);
const isEmailAlreadyRegistered = ref(false);
const displayInfoModal = ref(false);

const registerLoadingState = ref<ValidStateModel>("default");

const onApiResponse = (actualState: ValidStateModel) => {
  if (actualState === "success") {
    return router.push({ name: route.meta.nextPage as string });
  }
  if (actualState === "error") {
    registerLoadingState.value = "default";
  }
  return undefined;
};

const handleSubmit = (values: { registerValues: RegisterTypes; isOptinDisplayed: boolean }) => {
  if (registerLoadingState.value !== "default") {
    return;
  }

  registerLoadingState.value = "progress";

  const leafletCode = values.registerValues.leaflet.replace(/\s/g, "");

  const formData: RegisterPayload = {
    iCivilId: Number(values.registerValues.civility),
    sFirstName: values.registerValues.firstname,
    sLastName: values.registerValues.lastname,
    sEmail: values.registerValues.email,
    sZipcode: String(values.registerValues.zipcode),
    sMobile:
      parsePhoneNumberFromString(values.registerValues.mobile, "FR")?.number.substring(1) || "",
    oResponses: {
      sQuestion_101: leafletCode,
    },
  };

  if (values.registerValues.birthdate) {
    formData.oResponses.dBirthDate_1 = values.registerValues.birthdate;
  }

  if (
    values.isOptinDisplayed &&
    (values.registerValues.emailOptin || values.registerValues.smsOptin)
  ) {
    const temp: typeof formData.oOptins = {};

    if (values.registerValues.emailOptin) {
      temp.iOptin_2 = 1;
    }

    if (values.registerValues.smsOptin) {
      temp.iOptin_4 = 1;
    }

    formData.oOptins = temp;
  }

  api
    .register(formData)
    .then(() => api.getSessionCurrent())
    .then((response) => {
      updateStoreAfterSessionCurrent(
        response.data as APISessionCurrentResponse,
        userStore,
        trackingStore
      );

      registerLoadingState.value = "success";
    })
    .catch((err) => {
      registerLoadingState.value = "error";

      const errorStatus = err.response?.status;

      if (errorStatus === 409) {
        isEmailAlreadyRegistered.value = true;
      }
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(RegisterCover, {
      class: "w-full",
      "is-in-form": isInForm.value
    }, null, 8, ["is-in-form"]),
    _createElementVNode("section", _hoisted_2, [
      (!isInForm.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("h2", {
              class: "text-center text-[40px] font-[600] leading-none text-white lg:mb-3 lg:text-[54px] lg:uppercase",
              "data-cy": "register-instruction",
              innerHTML: _ctx.$t('register.baseline')
            }, null, 8, _hoisted_3),
            _createElementVNode("p", {
              class: "text-center text-[16px] text-white lg:text-[24px]",
              innerHTML: _ctx.$t('register.subBaseline')
            }, null, 8, _hoisted_4),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mx-auto my-8 h-[4px] w-[50px] bg-[#FD7412] lg:my-12" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(KrupsCta, {
                class: "uppercase",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (isInForm.value = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("register.cta")), 1)
                ]),
                _: 1
              })
            ])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("p", {
              class: "hidden text-center text-[16px] font-bold uppercase leading-tight text-white lg:block",
              innerHTML: _ctx.$t('register.instruction')
            }, null, 8, _hoisted_6),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "mx-auto mt-8 mb-24 hidden h-[4px] w-[50px] bg-[#FD7412] lg:block" }, null, -1)),
            (!isDisplayingRelog.value)
              ? (_openBlock(), _createBlock(RegisterTypeForm, {
                  key: 0,
                  ref: "registerTypeFormRef",
                  class: "mb-4",
                  "data-cy": "register-type-form",
                  "is-email-already-registered": isEmailAlreadyRegistered.value,
                  "register-loading-state": registerLoadingState.value,
                  onOpenInfoModal: _cache[1] || (_cache[1] = ($event: any) => (displayInfoModal.value = true)),
                  onFinishedAnimation: onApiResponse,
                  onFormSubmitted: handleSubmit,
                  onDisableIsEmailAlreadyRegistered: _cache[2] || (_cache[2] = ($event: any) => (isEmailAlreadyRegistered.value = false))
                }, null, 8, ["is-email-already-registered", "register-loading-state"]))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("register.form.optionalField")), 1)
          ], 64)),
      _createVNode(_Transition, { name: "slide" }, {
        default: _withCtx(() => [
          (displayInfoModal.value)
            ? (_openBlock(), _createBlock(RegisterInfoModal, {
                key: 0,
                onClose: _cache[3] || (_cache[3] = ($event: any) => (displayInfoModal.value = false))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})