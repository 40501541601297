import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-4 mt-8 text-center text-[21px] font-[600] text-white lg:hidden" }
const _hoisted_2 = { class: "mx-auto mb-8 inline-block rounded-[24px] bg-white px-4 pb-2 pt-3 text-center text-[21px] font-[600] leading-none text-primary lg:hidden" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "mb-16 flex items-center justify-center space-x-8" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src", "alt"]

import { useUserStore } from "@/store/user";
/* eslint import/no-cycle: "off" */
import { useExitUrl } from "@/composables/useShares";

import PageContainer from "@/components/PageContainer.vue";
import DiffusionProductsCarousel from "@/components/diffusion/DiffusionProductsCarousel.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Diffusion',
  setup(__props) {

const exitUrl = useExitUrl();
const user = useUserStore();

const shares = ["facebook", "instagram", "youtube"];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("diffusion.promoLabel")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(user).$state.codeLot), 1),
      _createElementVNode("p", {
        class: "mb-12 text-center text-[16px] font-[700] uppercase text-white lg:mb-0 lg:text-[24px]",
        innerHTML: _ctx.$t('diffusion.subtitle')
      }, null, 8, _hoisted_3),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "my-12 mx-auto hidden h-[4px] w-[50px] bg-primary lg:block" }, null, -1)),
      _createVNode(DiffusionProductsCarousel, { class: "mb-12" }),
      _createElementVNode("button", {
        type: "button",
        class: "mb-8 w-[200px] rounded-[24px] bg-primary py-4 text-center text-[14px] font-[700] uppercase leading-none text-white",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(exitUrl)(1)))
      }, _toDisplayString(_ctx.$t("diffusion.redirection")), 1),
      _createElementVNode("p", {
        class: "mb-6 text-center text-[16px] font-[700] leading-tight text-white lg:text-[21px]",
        innerHTML: _ctx.$t('diffusion.share.title')
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(shares, (share, index) => {
          return _createElementVNode("button", {
            key: `share-${share}`,
            type: "button",
            onClick: ($event: any) => (_unref(exitUrl)(index + 2))
          }, [
            _createElementVNode("img", {
              class: "block h-[48px] w-[48px]",
              src: require(`@/assets/diffusion/${share}.svg`),
              alt: share
            }, null, 8, _hoisted_7)
          ], 8, _hoisted_6)
        }), 64))
      ])
    ]),
    _: 1
  }))
}
}

})