import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-xl text-primary" }
const _hoisted_2 = { class: "text-gray-dark pb-1 text-xs" }
const _hoisted_3 = { class: "modal_body" }
const _hoisted_4 = { class: "pb-3" }
const _hoisted_5 = { class: "text-xs text-primary" }
const _hoisted_6 = { class: "text-center" }

import { ref } from "vue";
import { Form } from "vee-validate";
import { NText } from "@team-uep/n-inputs";
import { useI18n } from "vue-i18n";
import { useApiOp } from "@team-uep/vue-api-op";
import NCta from "@team-uep/n-cta";
import { NIconLoading } from "@team-uep/n-icon";
import { useUserStore } from "@/store/user";
import useUI from "@/composables/useUI";
import { paths } from "@/types/api.interfaces";

type Emails = {
  email1: string;
  email2: string;
  email3: string;
};

type ApiReturn = { [email: string]: { code: number } };


export default /*@__PURE__*/_defineComponent({
  __name: 'DiffusionEmailsModal',
  setup(__props) {

function emailObjToArray(emails: Emails): string[] {
  return Object.values(emails)
    .filter((email) => email)
    .map((email) => email);
}

const apiOp = useApiOp();
const { t } = useI18n();
const isLoading = ref(false);

const ui = useUI();

const globalSuccess = ref<false | string>(false);
const globalError = ref<false | string>(false);
const userStore = useUserStore();

const validationSchema = ref({
  email1: `email`,
  email2: `email`,
  email3: `email`,
});

const initialValues = ref<Emails>({
  email1: "",
  email2: "",
  email3: "",
});

const checkEmailsValidity = (emailList: string[]) => {
  if (emailList.length === 0) {
    globalError.value = t("errors.email.noEmail");
    return false;
  }

  if (new Set(emailList).size !== emailList.length) {
    globalError.value = t("errors.email.duplicateEmail");
    return false;
  }

  if (userStore.$state.email && emailList.includes(userStore.$state.email)) {
    globalError.value = t("errors.email.ownEmail");
    return false;
  }

  return true;
};

const displaySuccessErrors = (
  values: Emails,
  apiReturn: ApiReturn,
  {
    setFieldValue,
    setFieldError,
  }: {
    setFieldValue: (field: string, value: unknown) => void;
    setFieldError: (field: string, message: string | undefined) => void;
  }
) => {
  Object.keys(apiReturn).forEach((email) => {
    const success = apiReturn[email].code === 200;
    const key = Object.keys(values).find(
      (emailKey) => values[emailKey as "email1" | "email2" | "email3"] === email
    );
    if (key) {
      if (success) {
        setFieldValue(key, "");
      } else {
        setFieldError(key, t("errors.email.alreadyUsed"));
      }
    } else {
      throw new Error(`Email ${email} not found in the list ${JSON.stringify(values)}`);
    }
  });

  // If more than one good email is sent
  const successCount = Object.values(apiReturn).filter(({ code }) => code === 200).length;
  if (successCount > 0) {
    globalSuccess.value =
      successCount > 1 ? t("modals.shareEmail.success", 2) : t("modals.shareEmail.success", 1);
  }
};

const sendVirals = async (emails: string[]): Promise<ApiReturn> => {
  const { data } = await apiOp.diffusionSendVirals<
    paths["/diffusion/send_virals"]["post"]["responses"]["200"]["content"]["application/json"],
    paths["/diffusion/send_virals"]["post"]["requestBody"]["content"]["application/json"]
  >({
    emails,
  });
  const apiReturn = data.data[0] as ApiReturn;
  return apiReturn;
};

const submit = async (
  values: Emails,
  {
    setFieldValue,
    setFieldError,
  }: {
    setFieldValue: (field: string, value: unknown) => void;
    setFieldError: (field: string, message: string | undefined) => void;
    setErrors: (fields: Partial<Record<string, string | undefined>>) => void;
    setValues: (fields: Partial<Record<string, unknown>>) => void;
    setFieldTouched: (field: string, isTouched: boolean) => void;
    setTouched: (fields: Partial<Record<string, boolean>>) => void;
    // resetForm: (state?: Partial<FormState>) => void;
  }
) => {
  const emails = emailObjToArray(values);
  const isEmailsValid = checkEmailsValidity(emails);

  if (isEmailsValid) {
    isLoading.value = true;
    try {
      const apiReturn = await sendVirals(emails);
      displaySuccessErrors(values, apiReturn, { setFieldValue, setFieldError });
    } catch (error) {
      globalError.value = t("errors.api", { error: (error as { message: string }).message });
    }
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    ref: "form",
    class: "mx-auto max-w-[320px]",
    as: "form",
    "validation-schema": validationSchema.value,
    "initial-values": initialValues.value,
    onSubmit: ($event: any) => (null)
  }, {
    default: _withCtx(({ handleSubmit }) => [
      _createElementVNode("header", null, [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("modals.shareEmail.title")), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("modals.shareEmail.subtitle")), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3], (index) => {
          return _createElementVNode("div", { key: index }, [
            _createVNode(_unref(NText), {
              id: 'share-email-' + index,
              "data-cy": 'share-email-input-' + index,
              name: 'email' + index,
              type: "email",
              "label-position": "side",
              "error-trigger": "blur",
              "design-type": _unref(ui).panoply.inputs.designType,
              "border-color": _unref(ui).colors.primary,
              "error-color": _unref(ui).colors.error,
              placeholder: _ctx.$t('register.form.email'),
              autocomplete: "one-time-code"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("modals.shareEmail.email", { index: index })), 1)
              ]),
              _: 2
            }, 1032, ["id", "data-cy", "name", "design-type", "border-color", "error-color", "placeholder"])
          ])
        }), 64)),
        _createElementVNode("p", _hoisted_4, [
          _createElementVNode("small", _hoisted_5, _toDisplayString(_ctx.$t("modals.shareEmail.mentions")), 1)
        ]),
        _withDirectives(_createElementVNode("p", { class: "text-center text-sm text-error" }, _toDisplayString(globalError.value), 513), [
          [_vShow, globalError.value]
        ]),
        _withDirectives(_createElementVNode("div", { class: "text-center text-primary" }, _toDisplayString(globalSuccess.value), 513), [
          [_vShow, globalSuccess.value]
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(NCta), {
            class: "send-button",
            "data-cy": "share-mail-submit",
            type: "button",
            "border-radius": _unref(ui).panoply.cta.rounded ? '30px' : '0px',
            "background-color": _unref(ui).colors.primary,
            disabled: isLoading.value,
            onClick: ($event: any) => (handleSubmit(submit))
          }, _createSlots({
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("modals.shareEmail.submit")) + " ", 1)
            ]),
            _: 2
          }, [
            (isLoading.value)
              ? {
                  name: "icon",
                  fn: _withCtx(() => [
                    _createVNode(_unref(NIconLoading), {
                      color: _unref(ui).colors.primary
                    }, null, 8, ["color"])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["border-radius", "background-color", "disabled", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["validation-schema", "initial-values"]))
}
}

})