import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

import { useExitUrl } from "@/composables/useShares";
import useUI from "@/composables/useUI";
import NCta from "@team-uep/n-cta";


export default /*@__PURE__*/_defineComponent({
  __name: 'End',
  setup(__props) {

// eslint-disable-next-line import/no-cycle
const exitUrl = useExitUrl();
const ui = useUI();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", {
      class: "mx-auto mb-4 text-center text-[24px] font-bold leading-tight text-[#707070] lg:text-[32px]",
      innerHTML: _ctx.$t('end.title')
    }, null, 8, _hoisted_1),
    _createElementVNode("p", {
      class: "mb-8 text-center lg:mx-auto lg:max-w-[250px]",
      innerHTML: _ctx.$t('end.subtitle')
    }, null, 8, _hoisted_2),
    _createVNode(_unref(NCta), {
      class: "mx-auto mb-4",
      "border-radius": _unref(ui).panoply.cta.rounded ? '6px' : '0px',
      "background-color": { default: _unref(ui).colors.primary, hover: 'white', error: _unref(ui).colors.error },
      "text-color": { default: 'white', hover: _unref(ui).colors.primary },
      "border-color": { default: 'white', hover: _unref(ui).colors.primary },
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(exitUrl)(1)))
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" Découvrir les offres ")
      ])),
      _: 1
    }, 8, ["border-radius", "background-color", "text-color", "border-color"])
  ], 64))
}
}

})