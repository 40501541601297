import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import contactContentFr from "@/docs/contact/webmail-fr.dirt.html";
import contactContentEn from "@/docs/contact/webmail-en.dirt.html";
import { getCurrentDecliName } from "@/helpers/declis";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactModal',
  emits: ["updateSize"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

onMounted(() => {
  emit("updateSize", "max");
});

const route = useRoute();
const { t } = useI18n();
const contactContent = computed(() => {
  let htmlText = getCurrentDecliName(route) === "fr" ? contactContentFr : contactContentEn;

  htmlText = htmlText.replace(
    /{{footerContactEmail}}/g,
    `<a href="mailto:${t("footer.contact.email")}?subject=${t("footer.contact.subject")}">${t(
      "footer.contact.email"
    )}</a>`
  );
  htmlText = htmlText.replace(/{{footerContactClient}}/g, t("footer.contact.client"));

  return htmlText;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "contact",
    innerHTML: contactContent.value
  }, null, 8, _hoisted_1))
}
}

})