import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { inject, onMounted } from "vue";
import { Iubenda } from "@team-uep/vue-iubenda-op";


export default /*@__PURE__*/_defineComponent({
  __name: 'IubendaPrivacyPolicyModal',
  emits: ["updateSize"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

onMounted(() => {
  emit("updateSize", "max");
});

const iubenda = inject("iubenda") as Iubenda;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("iframe", {
      class: "cookieModal",
      src: _unref(iubenda).getPrivacyPolicyURL(),
      scrolling: "yes",
      frameborder: "0",
      allowtransparency: "true",
      title: "iubenda"
    }, null, 8, _hoisted_1)
  ]))
}
}

})