import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import charteContentFr from "@/docs/charte/test-pdf-fr.dirt.html";
import charteContentEn from "@/docs/charte/test-pdf-en.dirt.html";
import { getCurrentDecliName } from "@/helpers/declis";


export default /*@__PURE__*/_defineComponent({
  __name: 'CharteFbModal',
  emits: ["updateSize"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

onMounted(() => {
  emit("updateSize", "max");
});

const route = useRoute();
const charteContent = computed(() =>
  getCurrentDecliName(route) === "fr" ? charteContentFr : charteContentEn
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "charte",
      innerHTML: charteContent.value
    }, null, 8, _hoisted_1)
  ]))
}
}

})