import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/diffusion/coupon.png'


const _hoisted_1 = { class: "relative min-h-screen bg-[#232120] lg:flex lg:items-stretch lg:justify-center" }
const _hoisted_2 = { class: "image-container relative flex w-full flex-col items-center pb-8 lg:min-h-screen lg:justify-center lg:pt-12" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "relative hidden rounded-[24px] bg-white px-6 pb-3 pt-4 text-[21px] font-[600] uppercase leading-none text-primary lg:block" }
const _hoisted_7 = { class: "flex flex-col py-4 lg:min-w-[580px] lg:self-center lg:py-0" }
const _hoisted_8 = { class: "mx-auto w-full text-center lg:max-w-[580px]" }

import { useUserStore } from "@/store/user";

import LogoKrups from "@/components/ui/LogoKrups.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PageContainer',
  setup(__props) {

const user = useUserStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(LogoKrups, { class: "absolute top-0 z-10" }),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "pointer-events-none absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75" }, null, -1)),
      _createElementVNode("p", {
        class: "relative mt-16 mb-4 text-center text-[24px] font-[700] uppercase text-white lg:mb-8 lg:mt-0 lg:text-[40px] lg:leading-tight",
        innerHTML: _ctx.$t('diffusion.title')
      }, null, 8, _hoisted_3),
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        class: "relative mx-auto mb-4 block h-[140px] w-auto lg:mb-8 lg:h-[210px]",
        src: _imports_0,
        alt: "30% de réduction"
      }, null, -1)),
      _createElementVNode("p", {
        class: "relative text-center text-[14px] font-[600] text-white lg:mb-16 lg:text-[16px]",
        innerHTML: _ctx.$t('diffusion.promoDescription')
      }, null, 8, _hoisted_4),
      _createElementVNode("p", {
        class: "relative mb-4 hidden text-center text-[21px] font-[600] leading-tight text-white lg:block",
        innerHTML: _ctx.$t('diffusion.promoLabel')
      }, null, 8, _hoisted_5),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(user).$state.codeLot), 1),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute bottom-0 left-0 right-0 h-[4px] w-full bg-primary lg:hidden" }, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "absolute right-0 top-0 bottom-0 hidden w-[4px] bg-primary lg:block" }, null, -1))
    ]),
    _createElementVNode("section", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
}

})