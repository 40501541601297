import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/register/mobile-background.png'
import _imports_1 from '@/assets/register/desktop-background.png'


const _hoisted_1 = { class: "relative flex flex-col items-center justify-between lg:min-h-screen" }
const _hoisted_2 = ["innerHTML"]

import LogoKrups from "@/components/ui/LogoKrups.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterCover',
  props: {
    isInForm: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LogoKrups, { class: "absolute z-10" }),
    _createElementVNode("img", {
      class: _normalizeClass(["block w-full object-cover transition-all duration-300 ease-in-out lg:hidden", [_ctx.isInForm ? 'h-[30vh]' : 'h-[60vh]']]),
      src: _imports_0,
      alt: ""
    }, null, 2),
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "hidden h-screen w-full object-cover lg:block",
      src: _imports_1,
      alt: ""
    }, null, -1)),
    _createElementVNode("div", {
      class: _normalizeClass(["pointer-events-none absolute top-0 left-0 right-0 bottom-[4px] bg-black transition-opacity duration-300 ease-in-out lg:hidden", [_ctx.isInForm ? 'opacity-75' : 'opacity-0']])
    }, null, 2),
    (_ctx.isInForm)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "absolute top-1/2 left-1/2 mx-auto block w-full -translate-x-1/2 -translate-y-1/2 text-center text-[16px] font-[700] uppercase text-white lg:hidden",
          innerHTML: _ctx.$t('register.instruction')
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[4px] w-full bg-[#FD7412] lg:hidden" }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute top-0 bottom-0 right-0 hidden h-screen w-[4px] bg-[#FD7412] lg:block" }, null, -1))
  ]))
}
}

})