import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-4" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

import NCopy from "@team-uep/n-copy";
import { useTrackClickUrlCopy } from "@/composables/useTrackClicks";
import { useUrlCopyShareURL } from "@/composables/useShares";
import useUI from "@/composables/useUI";


export default /*@__PURE__*/_defineComponent({
  __name: 'ShareLinkModal',
  setup(__props) {

const shareURL = useUrlCopyShareURL();
const handleTrackClick = useTrackClickUrlCopy();
const ui = useUI();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h3", {
        class: "text-xl text-primary",
        innerHTML: _ctx.$t('modals.shareLink.title')
      }, null, 8, _hoisted_2),
      _createElementVNode("p", {
        class: "text-gray-dark pb-1 text-xs",
        innerHTML: _ctx.$t('modals.shareLink.subtitle')
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_unref(NCopy), {
        "main-color": _unref(ui).colors.primary,
        class: "w-full",
        "data-cy": "share-url",
        value: _unref(shareURL),
        "message-checked": _ctx.$t('modals.shareLink.confirm'),
        onCopy: _cache[0] || (_cache[0] = ($event: any) => (_unref(handleTrackClick)()))
      }, null, 8, ["main-color", "value", "message-checked"])
    ])
  ]))
}
}

})