import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative flex w-full items-center lg:px-3" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "mb-4 flex h-[90px] w-full items-center justify-center" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "flex h-[40px] w-full items-center justify-center" }
const _hoisted_6 = { class: "mx-auto max-w-[100px] text-center text-[14px] font-bold text-black" }

import type { Swiper as SwiperInstance } from "swiper";
import { ref } from "vue";
// eslint-disable-next-line import/extensions, import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/vue";
import { useI18n } from "vue-i18n";

import { getProducts } from "@/products";
import { useExitUrl } from "@/composables/useShares";


export default /*@__PURE__*/_defineComponent({
  __name: 'DiffusionProductsCarousel',
  setup(__props) {

const { t } = useI18n();
const exitUrl = useExitUrl();
const products = getProducts(t);

const swiperInstance = ref<SwiperInstance | null>(null);

const onSwiper = (swiper: SwiperInstance) => {
  swiperInstance.value = swiper;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["hidden h-[28px] w-[28px] items-center justify-center rounded-full bg-primary text-white transition-opacity duration-300 ease-in-out lg:inline-flex", [swiperInstance.value && swiperInstance.value.isBeginning ? 'opacity-50' : '']]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (swiperInstance.value ? swiperInstance.value.slidePrev() : null))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        class: "h-6 w-6",
        fill: "none",
        viewBox: "0 0 24 24",
        stroke: "currentColor",
        "stroke-width": "2"
      }, [
        _createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          d: "M15 19l-7-7 7-7"
        })
      ], -1)
    ]), 2),
    _createVNode(_unref(Swiper), {
      "slides-per-view": "auto",
      "space-between": 24,
      breakpoints: {
        1024: {
          spaceBetween: 24,
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
      onSwiper: onSwiper
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(products), (carouselProduct, i) => {
          return (_openBlock(), _createBlock(_unref(SwiperSlide), {
            key: `product-${i}`,
            class: "mb-2 flex !h-[180px] cursor-pointer flex-col rounded-3xl"
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                type: "button",
                class: "h-full rounded-[24px] bg-white py-4",
                onClick: ($event: any) => (_unref(exitUrl)(carouselProduct.linkNumber))
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: carouselProduct.image,
                    alt: carouselProduct.name,
                    class: "mx-auto block h-auto w-[80px]"
                  }, null, 8, _hoisted_4)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(carouselProduct.name), 1)
                ])
              ], 8, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["hidden h-[28px] w-[28px] items-center justify-center rounded-full bg-primary text-white transition-opacity duration-300 ease-in-out lg:inline-flex", [swiperInstance.value && swiperInstance.value.isEnd ? 'opacity-50' : '']]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (swiperInstance.value ? swiperInstance.value.slideNext() : null))
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        class: "h-6 w-6",
        fill: "none",
        viewBox: "0 0 24 24",
        stroke: "currentColor",
        "stroke-width": "2"
      }, [
        _createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          d: "M9 5l7 7-7 7"
        })
      ], -1)
    ]), 2)
  ]))
}
}

})