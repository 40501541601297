import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pt-4" }
const _hoisted_2 = { class: "text-xl text-primary" }
const _hoisted_3 = { class: "list-friends list-unstyled" }
const _hoisted_4 = { class: "table" }
const _hoisted_5 = { class: "m-auto mx-auto table pt-2 pl-4 sm:mx-0" }

import { ref } from "vue";
import { useApiOp } from "@team-uep/vue-api-op";
import { useI18n } from "vue-i18n";
import NCta from "@team-uep/n-cta";
import useUI from "@/composables/useUI";
// import NCopy from "@team-uep/n-copy";

type Friend = {
  email: string;
  mailto: string;
  subscribed: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SummaryModal',
  setup(__props) {

const ui = useUI();

const friends = ref<Friend[]>([]);
const apiOp = useApiOp();
const { t } = useI18n();
apiOp.diffusionSummaryViral().then(({ data }) => {
  // eslint-disable-next-line camelcase
  friends.value = (data.data as { Email: string; id_UE_Envoi: string }[]).map((friend) => {
    const refVars = {
      websiteUrl: window.location.origin,
      FriendEmail: friend.Email,
      id_UE_Envoi: friend.id_UE_Envoi,
    };

    return {
      email: friend.Email,
      mailto: `mailto:${friend.Email}?subject=${encodeURIComponent(
        t("diffusionPage_summary_relance_subject")
      )}&body=${encodeURIComponent(t("diffusionPage_summary_relance_body", refVars))}`,
      subscribed: false,
    };
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("modals.summary.title")), 1)
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(friends.value, (friend, key) => {
        return (_openBlock(), _createElementBlock("li", {
          key: key,
          class: "friends-list-li row flex flex-wrap items-center justify-between"
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(friend.email), 1),
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_unref(NCta), {
              "data-cy": 'friend-mail-' + key,
              type: "a",
              href: friend.mailto,
              disabled: friend.subscribed,
              "border-radius": _unref(ui).panoply.cta.rounded ? '30px' : '0px',
              "background-color": _unref(ui).colors.primary,
              target: "_blank",
              class: "actions"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(friend.subscribed ? _ctx.$t("modals.summary.registered") : _ctx.$t("modals.summary.relance")), 1)
              ]),
              _: 2
            }, 1032, ["data-cy", "href", "disabled", "border-radius", "background-color"])
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})