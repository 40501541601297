import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full bg-[#232120]" }
const _hoisted_2 = { class: "flex flex-row flex-wrap items-center justify-center py-4 text-[14px] text-white" }
const _hoisted_3 = {
  key: 1,
  class: "px-2"
}
const _hoisted_4 = {
  key: 3,
  class: "px-2"
}
const _hoisted_5 = {
  key: 5,
  class: "px-2"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 7,
  class: "px-2"
}
const _hoisted_8 = {
  key: 0,
  class: "mx-auto max-w-5xl pb-8 text-white"
}
const _hoisted_9 = ["innerHTML"]

import { inject } from "vue";
import { Iubenda } from "@team-uep/vue-iubenda-op";
import useModal from "@/composables/useModal";


export default /*@__PURE__*/_defineComponent({
  __name: 'SiteFooter',
  setup(__props) {

const iubenda = inject("iubenda") as Iubenda;
const { openModal } = useModal();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$te('footer.terms'))
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            "data-cy": "terms-open-cta",
            class: "cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(openModal)('Terms')))
          }, _toDisplayString(_ctx.$t("footer.terms")), 1))
        : _createCommentVNode("", true),
      (_ctx.$te('footer.terms'))
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "|"))
        : _createCommentVNode("", true),
      (_ctx.$te('footer.cookiesPreferences'))
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "iubenda-cs-preferences-link cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(iubenda).openPreferencesModal()))
          }, _toDisplayString(_ctx.$t("footer.cookiesPreferences")), 1))
        : _createCommentVNode("", true),
      (_ctx.$te('footer.cookiesPreferences'))
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "|"))
        : _createCommentVNode("", true),
      (_ctx.$te('footer.cookies'))
        ? (_openBlock(), _createElementBlock("button", {
            key: 4,
            class: "cursor-pointer",
            "data-cy": "cookies-open-cta",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(openModal)('IubendaPrivacyPolicy')))
          }, _toDisplayString(_ctx.$t("footer.cookies")), 1))
        : _createCommentVNode("", true),
      (_ctx.$te('footer.cookies'))
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "|"))
        : _createCommentVNode("", true),
      (_ctx.$te('footer.contact.button'))
        ? (_openBlock(), _createElementBlock("a", {
            key: 6,
            "data-cy": "contact-cta",
            href: `mailto:${_ctx.$t('footer.contact.email')}?subject=${_ctx.$t('footer.contact.subject')}`,
            target: "_blank",
            class: "cursor-pointer"
          }, _toDisplayString(_ctx.$t("footer.contact.button")), 9, _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.$te('footer.contact.button'))
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "|"))
        : _createCommentVNode("", true),
      (_ctx.$te('footer.realisation.button'))
        ? (_openBlock(), _createElementBlock("button", {
            key: 8,
            "data-cy": "realisation-cta",
            class: "cursor-pointer",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(openModal)('Realisation')))
          }, _toDisplayString(_ctx.$t("footer.realisation.button")), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$t('footer.mentions'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("p", {
            class: "px-4 text-center text-sm",
            innerHTML: _ctx.$t('footer.mentions')
          }, null, 8, _hoisted_9)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})