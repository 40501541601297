import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/register/leaflet-code-instruction.png'


const _hoisted_1 = { class: "absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50" }
const _hoisted_2 = { class: "relative w-[310px] rounded-md bg-white shadow-2xl" }
const _hoisted_3 = { class: "flex flex-col items-center justify-center pt-20 pb-6" }
const _hoisted_4 = ["innerHTML"]


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterInfoModal',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "absolute top-0 left-1/2 -mt-[70px] block h-[140px] w-[140px] -translate-x-1/2"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", {
          class: "mb-6 text-center",
          innerHTML: _ctx.$t('register.leafletPop.description')
        }, null, 8, _hoisted_4),
        _createElementVNode("button", {
          type: "button",
          class: "relative flex items-center justify-center rounded-[24px] bg-[#FD7412] py-3 px-10 text-center text-[14px] font-bold uppercase text-white",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
        }, _toDisplayString(_ctx.$t("register.leafletPop.cta")), 1)
      ])
    ])
  ]))
}
}

})