import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { inject, watch } from "vue";
import { LocationQueryValue, useRoute, useRouter } from "vue-router";
import { ApiOpBackendEventName, useApiOp } from "@team-uep/vue-api-op";
import { MmTro } from "@team-uep/vue-mmtro-op";
import { useIubenda, IubendaEventName } from "@team-uep/vue-iubenda-op";

import { APISessionTokenResponse } from "@/types/api-extended.interfaces";
import { useTrackingStore } from "@/store/tracking";
import initPersistedState from "@/store/initPersistedState";
import initMmtro from "@/composables/initMmtro";

import SiteFooter from "@/components/SiteFooter.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import Modal from "@/components/modals/Modal.vue";
import { getCurrentDecli } from "./helpers/declis";
import useUI from "./composables/useUI";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const iubenda = useIubenda();
const mmtro = inject<MmTro>("mmTro");

const route = useRoute();
const router = useRouter();
const api = useApiOp();
const trackingStore = useTrackingStore();
const ui = useUI();

// Store data in session storage
initPersistedState("opState");

// Automatically send mmtro tag
initMmtro();

watch(
  () => route.name,
  (newRoute, oldRoute) => {
    window.scrollTo(0, 0);

    // Init iubenda only first time
    // wait route to calculate current decli
    if (newRoute && !oldRoute) {
      const currentDecli = getCurrentDecli(route);
      if (iubenda) {
        iubenda.initialize({
          csConfiguration: currentDecli.iubendaCsConfiguration,
          buttonColor: ui.colors.primary,
        });
      }
    }
  }
);

// Clean URL by removing query params (after storing) exept modal query
// Update it only on init or if query changed
watch(
  () => route.query,
  (oldQuery) => {
    const nextQuery: { modal?: string | LocationQueryValue[] } = {};
    if (route.query.modal) {
      nextQuery.modal = route.query.modal;
    }
    // Replace route only if query added (or modal changed)
    if (JSON.stringify(oldQuery) !== JSON.stringify(nextQuery)) {
      router.replace({ query: nextQuery });
    }
  },
  { immediate: true }
);

// Iubenda
if (iubenda) {
  iubenda.on(IubendaEventName.CONSENT_GIVEN_MMTRO, () => {
    iubenda.mmtroConsent = true;
    if (mmtro) {
      mmtro.sendRtg();
    }
    return Promise.resolve();
  });

  iubenda.on(IubendaEventName.CONSENT_REFUSED_MMTRO, () => {
    iubenda.mmtroConsent = false;
    return Promise.resolve();
  });
}

// When loading the page, a session-token is generated.
// Store session-token tracking data to tracking store.
api.on(ApiOpBackendEventName.SESSION_TOKEN, (response) => {
  const data = response.data as APISessionTokenResponse;

  trackingStore.$patch({
    idvisit: data.data[0].visitId,
    mmtro: {
      tagid: data.data[0].oTagInfo.tagid || "6578716-48e850f7a8468d584517527b92eaf8c0",
    },
  });

  return Promise.resolve(response);
});

api.on(ApiOpBackendEventName.REGISTERED, (response) => {
  mmtro?.conversion();

  return Promise.resolve(response);
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in",
          duration: 500
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }),
    (_ctx.$route.name)
      ? (_openBlock(), _createBlock(SiteFooter, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(Modal),
    _createVNode(LoadingScreen, {
      "data-cy": "routeLoaded",
      display: !_ctx.$route.name
    }, null, 8, ["display"])
  ], 64))
}
}

})