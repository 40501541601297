import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  href: "https://numberly.com/fr",
  target: "_blank"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

import { computed } from "vue";
import { useRoute } from "vue-router";
import NumberlyInternationalLogo from "@/assets/logo/numberly-international.svg";
import NumberlyFranceLogo from "@/assets/logo/numberly-france.svg";
import { getCurrentDecliName } from "@/helpers/declis";


export default /*@__PURE__*/_defineComponent({
  __name: 'RealisationModal',
  setup(__props) {

const numberlyInternationalLogoSrc = NumberlyInternationalLogo;
const numberlyFranceLogo = NumberlyFranceLogo;
const route = useRoute();

const numberlyLogo = computed(() =>
  getCurrentDecliName(route) === "fr" ? numberlyFranceLogo : numberlyInternationalLogoSrc
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, [
      _createElementVNode("a", _hoisted_2, [
        _createElementVNode("img", {
          class: "mx-auto block max-w-xs",
          src: numberlyLogo.value,
          alt: "Numberly logo"
        }, null, 8, _hoisted_3)
      ])
    ]),
    _createElementVNode("p", {
      innerHTML: _ctx.$t('footer.realisation.address')
    }, null, 8, _hoisted_4),
    _createElementVNode("p", null, [
      _createElementVNode("a", {
        href: _ctx.$t('footer.realisation.numberly'),
        target: "_blank"
      }, "www.numberly.com", 8, _hoisted_5),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("footer.realisation.contact")), 1),
      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("footer.realisation.email")) + " : ", 1),
      _createElementVNode("a", {
        class: "text-primary",
        "data-cy": "mailto-contact",
        href: `mailto:${_ctx.$t('footer.realisation.mailto')}?subject=${_ctx.$t('footer.contact.subject')}`,
        target: "_blank"
      }, _toDisplayString(_ctx.$t("footer.realisation.mailto")), 9, _hoisted_6)
    ])
  ]))
}
}

})