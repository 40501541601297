import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import termsContentFr from "@/docs/terms/test-pdf-fr.dirt.html";
import termsContentEn from "@/docs/terms/test-pdf-en.dirt.html";
import { getCurrentDecliName } from "@/helpers/declis";


export default /*@__PURE__*/_defineComponent({
  __name: 'TermsModal',
  emits: ["updateSize"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

onMounted(() => {
  emit("updateSize", "max");
});

const route = useRoute();
const termsContent = computed(() =>
  getCurrentDecliName(route) === "fr" ? termsContentFr : termsContentEn
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "terms",
    innerHTML: termsContent.value
  }, null, 8, _hoisted_1))
}
}

})