import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/info-icon.svg'


const _hoisted_1 = { class: "relative mx-auto ml-[32px] max-w-[300px]" }
const _hoisted_2 = { class: "relative mx-auto ml-[32px] max-w-[300px]" }
const _hoisted_3 = { class: "relative mx-auto ml-[32px] max-w-[300px]" }
const _hoisted_4 = { class: "relative mx-auto ml-[32px] max-w-[300px]" }
const _hoisted_5 = { class: "relative mx-auto ml-[32px] max-w-[300px]" }
const _hoisted_6 = { class: "relative mx-auto ml-[32px] max-w-[300px]" }
const _hoisted_7 = { class: "relative mx-auto ml-[32px] max-w-[300px]" }
const _hoisted_8 = { class: "relative mx-auto ml-[32px] max-w-[300px]" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "m-auto table" }

import { ref, watch, computed, onMounted } from "vue";
import { Form, SubmissionHandler } from "vee-validate";
import { useApiOp } from "@team-uep/vue-api-op";
import { NCheckbox, NText, NMask, NRadios, NPhone } from "@team-uep/n-inputs";
import { NTypeform, NTypeformSlide, NTypeformCta, NTypeformNavigation } from "@team-uep/n-typeform";
import { NCtaLoader, ValidStateModel } from "@team-uep/n-cta-loader";

import useUI from "@/composables/useUI";
import useRegister, { RegisterTypes, RegisterField } from "./useRegister";

type GenericFormValues = {
  [x: string]: unknown;
};

/**
 * Send register data
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterTypeForm',
  props: {
    isEmailAlreadyRegistered: { type: Boolean },
    registerLoadingState: {}
  },
  emits: ["form-submitted", "disableIsEmailAlreadyRegistered", "finishedAnimation", "open-info-modal"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const registerLoadingState = computed(() => props.registerLoadingState);

const { form, initialValues, schema, handlePhoneChange } = useRegister(props);
const ui = useUI();
const api = useApiOp();

const currentSlide = ref(0);
const displayOptin = ref(false);

const validity = ref({
  [RegisterField.Leaflet]: false,
  [RegisterField.Civility]: false,
  [RegisterField.Firstname]: false,
  [RegisterField.Lastname]: false,
  [RegisterField.Email]: false,
  [RegisterField.Birthdate]: false,
  [RegisterField.Zipcode]: false,
  [RegisterField.Mobile]: false,
  [RegisterField.EmailOptin]: false,
  [RegisterField.Terms]: false,
} as { [key in RegisterField]: boolean });

/**
 * Slide name list
 */
const slides = [
  RegisterField.Leaflet,
  RegisterField.Civility,
  RegisterField.Firstname,
  RegisterField.Lastname,
  RegisterField.Email,
  RegisterField.Birthdate,
  RegisterField.Zipcode,
  RegisterField.Mobile,
  RegisterField.EmailOptin,
  RegisterField.Terms,
] as RegisterField[];

/**
 * Update a field validity with vee-validate
 */
const updateFieldValidity = async (name: RegisterField | string, callback?: () => void) => {
  if (!form.value) {
    return;
  }

  const result = await form.value.validateField(name as RegisterField);
  validity.value[name as RegisterField] = result.valid;

  if (callback) {
    callback();
  }
};

const handleSubmitForm: SubmissionHandler<GenericFormValues, unknown> = (
  values: GenericFormValues
) => {
  emit("form-submitted", {
    registerValues: values as RegisterTypes,
    isOptinDisplayed: displayOptin.value,
  });
};

const handleFinishedAnimation = (value: ValidStateModel) => {
  emit("finishedAnimation", value);
};
/**
 * Test slide validity by index
 */
const checkSlideAuth = (index: number) => {
  const next = Math.min(slides.length - 1, Math.max(0, index));
  for (let i = 0; i < next; i += 1) {
    const slideName = slides[i];
    if (!validity.value[slideName]) {
      return false;
    }
  }
  return true;
};

/**
 * Change slide by index
 */
const goSlide = (index: number) => {
  if (checkSlideAuth(index) && index <= slides.length) {
    currentSlide.value = index;

    // Index 5 is for birthdate. It's an optional field, we should be able to
    // skip it without entering any value.
    if (index === 5) {
      updateFieldValidity(RegisterField.Birthdate);
    }
  }
};

/**
 * Go to previous slide
 */
const prevSlide = () => {
  goSlide(currentSlide.value - 1);
};

/**
 * Go to next slide
 */
const nextSlide = () => {
  goSlide(currentSlide.value + 1);
};

/**
 * Go to next unvalid slide
 */
const jumpSlide = () => {
  const num = slides.findIndex((name) => validity.value[name] === false);
  goSlide(num < 0 ? currentSlide.value + 1 : num);
};

/**
 * Required slides count
 */
const requiredSlides = slides.filter((name) => name !== RegisterField.EmailOptin);

/**
 * Total slides count
 */
const totalSlidesCount = slides.length + 1;

/**
 * Valid slides count for required slides
 */
const completedSlidesCount = computed(
  () => requiredSlides.map((name) => validity.value[name]).filter((valid) => valid).length
);

// Update email errors if email is already used
watch(
  () => props.isEmailAlreadyRegistered,
  (value, oldValue) => {
    if (value && !oldValue) {
      validity.value[RegisterField.Email] = false;
      goSlide(slides.indexOf(RegisterField.Email));
    }
  }
);

watch(
  () => currentSlide.value,
  () => {
    const className = "optin-active";
    const typeformContainer = document.querySelector(".slide-container");

    if (!typeformContainer) {
      return;
    }

    if (currentSlide.value === 8) {
      typeformContainer.classList.add(className);
    } else {
      typeformContainer.classList.remove(className);
    }
  }
);

const isDedup = async (value: string) => {
  try {
    if (value.includes("@") && value.includes(".")) {
      const optin = (await api.dedupEmail(value)).data.data[0] as {
        optins: {
          // eslint-disable-next-line camelcase
          iOptinMarque_2: number;
        };
      };

      if (optin.optins.iOptinMarque_2 === 0 || optin.optins.iOptinMarque_2 === null) {
        displayOptin.value = true;
      } else if (optin.optins.iOptinMarque_2 === 1) {
        displayOptin.value = false;
      } else {
        displayOptin.value = false;
      }
    } else {
      displayOptin.value = false;
    }
  } catch {
    displayOptin.value = true;
  }
};

/**
 * Called if email modified
 */
const onEmailChanged = (hasError: string | undefined, values: RegisterTypes) => {
  isDedup(values.email);
  if (props.isEmailAlreadyRegistered) {
    emit("disableIsEmailAlreadyRegistered", false);
  }

  if (hasError) {
    updateFieldValidity(RegisterField.Email);
  }
};

// On component mounted, if user have pre-filled the form using URL query
// parameters, we need to force the validation of those specific fields.
onMounted(() => {
  if (initialValues.value.email) {
    updateFieldValidity(RegisterField.Email);
  }

  if (initialValues.value.firstname) {
    updateFieldValidity(RegisterField.Firstname);
  }

  if (initialValues.value.lastname) {
    updateFieldValidity(RegisterField.Lastname);
  }

  if (initialValues.value.civility) {
    updateFieldValidity(RegisterField.Civility);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    ref_key: "form",
    ref: form,
    class: "mx-auto max-w-[320px] overflow-hidden px-[1px]",
    as: "div",
    "validation-schema": _unref(schema),
    "initial-values": _unref(initialValues),
    onSubmit: ($event: any) => (null)
  }, {
    default: _withCtx(({ handleSubmit, errors, values }) => [
      _createVNode(_unref(NTypeform), {
        class: "typeform",
        "current-slide": currentSlide.value
      }, {
        slides: _withCtx(() => [
          _createVNode(_unref(NTypeformSlide), { index: 1 }, {
            default: _withCtx(() => [
              _cache[25] || (_cache[25] = _createElementVNode("h3", { class: "typeform-input-index" }, "1.", -1)),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_unref(NMask), {
                  id: "input-leaflet",
                  "data-cy": "input-leaflet",
                  name: "leaflet",
                  type: "text",
                  class: "leaflet-code",
                  mask: "# ### ### ### ###",
                  placeholder: _ctx.$t('register.form.codePlaceholder'),
                  "error-color": _unref(ui).colors.error,
                  "design-type": _unref(ui).panoply.inputs.designType,
                  "selected-background-color": _unref(ui).colors.primary,
                  onInput: _cache[0] || (_cache[0] = ($event: any) => (updateFieldValidity('leaflet'))),
                  onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (jumpSlide()), ["enter"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.form.codeLabel")), 1)
                  ]),
                  _: 1
                }, 8, ["placeholder", "error-color", "design-type", "selected-background-color"]),
                _createElementVNode("button", {
                  type: "button",
                  class: "icon-info",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('open-info-modal')))
                }, _cache[24] || (_cache[24] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "Info"
                  }, null, -1)
                ]))
              ]),
              (validity.value.leaflet)
                ? (_openBlock(), _createBlock(_unref(NTypeformCta), {
                    key: 0,
                    "data-cy": "cta-next",
                    "background-color": _unref(ui).colors.primary,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (jumpSlide()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("ok")), 1)
                    ]),
                    _: 1
                  }, 8, ["background-color"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_unref(NTypeformSlide), { index: 2 }, {
            default: _withCtx(() => [
              _cache[26] || (_cache[26] = _createElementVNode("h3", { class: "typeform-input-index" }, "2.", -1)),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_unref(NRadios), {
                  id: "input-civility",
                  "data-cy": "input-civility",
                  name: "civility",
                  "design-type": "box",
                  "border-type": "squared",
                  "error-color": _unref(ui).colors.error,
                  "selected-background-color": _unref(ui).colors.primary,
                  "selected-color": "white",
                  "border-color": _unref(ui).colors.primary,
                  values: { [_ctx.$t('register.form.ms')]: 2, [_ctx.$t('register.form.mr')]: 1 },
                  onInput: _cache[4] || (_cache[4] = ($event: any) => (updateFieldValidity('civility', jumpSlide)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.form.genderLabel")), 1)
                  ]),
                  _: 1
                }, 8, ["error-color", "selected-background-color", "border-color", "values"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(NTypeformSlide), { index: 3 }, {
            default: _withCtx(() => [
              _cache[27] || (_cache[27] = _createElementVNode("h3", { class: "typeform-input-index" }, "3.", -1)),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(NText), {
                  id: "input-firstname",
                  "data-cy": "input-firstname",
                  name: "firstname",
                  type: "text",
                  placeholder: _ctx.$t('register.form.firstnamePlaceholder'),
                  "error-color": _unref(ui).colors.error,
                  "design-type": _unref(ui).panoply.inputs.designType,
                  "selected-background-color": _unref(ui).colors.primary,
                  onInput: _cache[5] || (_cache[5] = ($event: any) => (updateFieldValidity('firstname'))),
                  onKeyup: _cache[6] || (_cache[6] = _withKeys(($event: any) => (jumpSlide()), ["enter"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.form.firstnameLabel")), 1)
                  ]),
                  _: 1
                }, 8, ["placeholder", "error-color", "design-type", "selected-background-color"]),
                (validity.value.firstname)
                  ? (_openBlock(), _createBlock(_unref(NTypeformCta), {
                      key: 0,
                      "data-cy": "cta-next",
                      "background-color": _unref(ui).colors.primary,
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (jumpSlide()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("ok")), 1)
                      ]),
                      _: 1
                    }, 8, ["background-color"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(NTypeformSlide), { index: 4 }, {
            default: _withCtx(() => [
              _cache[28] || (_cache[28] = _createElementVNode("h3", { class: "typeform-input-index" }, "4.", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_unref(NText), {
                  id: "input-lastname",
                  "data-cy": "input-lastname",
                  name: "lastname",
                  type: "text",
                  placeholder: _ctx.$t('register.form.lastnamePlaceholder'),
                  "error-color": _unref(ui).colors.error,
                  "design-type": _unref(ui).panoply.inputs.designType,
                  "selected-background-color": _unref(ui).colors.primary,
                  onInput: _cache[8] || (_cache[8] = ($event: any) => (updateFieldValidity('lastname'))),
                  onKeyup: _cache[9] || (_cache[9] = _withKeys(($event: any) => (jumpSlide()), ["enter"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.form.lastnameLabel")), 1)
                  ]),
                  _: 1
                }, 8, ["placeholder", "error-color", "design-type", "selected-background-color"]),
                (validity.value.lastname)
                  ? (_openBlock(), _createBlock(_unref(NTypeformCta), {
                      key: 0,
                      "data-cy": "cta-next",
                      "background-color": _unref(ui).colors.primary,
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (jumpSlide()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("ok")), 1)
                      ]),
                      _: 1
                    }, 8, ["background-color"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(NTypeformSlide), { index: 5 }, {
            default: _withCtx(() => [
              _cache[29] || (_cache[29] = _createElementVNode("h3", { class: "typeform-input-index" }, "5.", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(NText), {
                  id: "input-email",
                  "data-cy": "input-email",
                  name: "email",
                  type: "email",
                  placeholder: _ctx.$t('register.form.emailPlaceholder'),
                  "error-color": _unref(ui).colors.error,
                  "design-type": _unref(ui).panoply.inputs.designType,
                  "selected-background-color": _unref(ui).colors.primary,
                  onInput: ($event: any) => (onEmailChanged(errors.email, values as RegisterTypes)),
                  onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => (updateFieldValidity('email', jumpSlide)), ["enter"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.form.emailLabel")), 1)
                  ]),
                  _: 2
                }, 1032, ["placeholder", "error-color", "design-type", "selected-background-color", "onInput"]),
                (!errors.email && values.email.length > 4)
                  ? (_openBlock(), _createBlock(_unref(NTypeformCta), {
                      key: 0,
                      "data-cy": "cta-next",
                      "background-color": _unref(ui).colors.primary,
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (updateFieldValidity('email', jumpSlide)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("ok")), 1)
                      ]),
                      _: 1
                    }, 8, ["background-color"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1024),
          _createVNode(_unref(NTypeformSlide), { index: 6 }, {
            default: _withCtx(() => [
              _cache[30] || (_cache[30] = _createElementVNode("h3", { class: "typeform-input-index" }, "6.", -1)),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(NMask), {
                  id: "input-birthdate",
                  "data-cy": "input-birthday",
                  name: "birthdate",
                  type: "text",
                  placeholder: _ctx.$t('register.form.birthdayPlaceholder'),
                  mask: "##/##/####",
                  "error-color": _unref(ui).colors.error,
                  "design-type": _unref(ui).panoply.inputs.designType,
                  "selected-background-color": _unref(ui).colors.primary,
                  onInput: _cache[13] || (_cache[13] = ($event: any) => (updateFieldValidity('birthdate'))),
                  onKeyup: _cache[14] || (_cache[14] = _withKeys(($event: any) => (jumpSlide()), ["enter"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.form.birthdayLabel")), 1)
                  ]),
                  _: 1
                }, 8, ["placeholder", "error-color", "design-type", "selected-background-color"]),
                (validity.value.birthdate)
                  ? (_openBlock(), _createBlock(_unref(NTypeformCta), {
                      key: 0,
                      "data-cy": "cta-next",
                      "background-color": _unref(ui).colors.primary,
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (jumpSlide()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("ok")), 1)
                      ]),
                      _: 1
                    }, 8, ["background-color"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(NTypeformSlide), { index: 7 }, {
            default: _withCtx(() => [
              _cache[31] || (_cache[31] = _createElementVNode("h3", { class: "typeform-input-index" }, "7.", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_unref(NMask), {
                  id: "input-zipcode",
                  "data-cy": "input-zipcode",
                  name: "zipcode",
                  type: "number",
                  placeholder: _ctx.$t('register.form.zipcodePlaceholder'),
                  mask: "#####",
                  "error-color": _unref(ui).colors.error,
                  "design-type": _unref(ui).panoply.inputs.designType,
                  "selected-background-color": _unref(ui).colors.primary,
                  onInput: _cache[16] || (_cache[16] = ($event: any) => (updateFieldValidity('zipcode'))),
                  onKeyup: _cache[17] || (_cache[17] = _withKeys(($event: any) => (jumpSlide()), ["enter"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.form.zipcodeLabel")), 1)
                  ]),
                  _: 1
                }, 8, ["placeholder", "error-color", "design-type", "selected-background-color"]),
                (validity.value.zipcode)
                  ? (_openBlock(), _createBlock(_unref(NTypeformCta), {
                      key: 0,
                      "data-cy": "cta-next",
                      "background-color": _unref(ui).colors.primary,
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (jumpSlide()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("ok")), 1)
                      ]),
                      _: 1
                    }, 8, ["background-color"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(NTypeformSlide), { index: 8 }, {
            default: _withCtx(() => [
              _cache[32] || (_cache[32] = _createElementVNode("h3", { class: "typeform-input-index" }, "8.", -1)),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_unref(NPhone), {
                  id: "input-phone",
                  "data-cy": "input-mobile",
                  name: "mobile",
                  placeholder: _ctx.$t('register.form.mobilePlaceholder'),
                  type: _unref(ui).panoply.inputs.designType,
                  "background-color": "transparent",
                  countries: [
                  {
                    isoCode: 'FR',
                    name: 'France',
                    indicator: '+33',
                    imgSrc: require('@/assets/flags/fr.svg'),
                  },
                ],
                  onChange: _unref(handlePhoneChange),
                  onInput: _cache[19] || (_cache[19] = ($event: any) => (updateFieldValidity('mobile'))),
                  onKeyup: _cache[20] || (_cache[20] = _withKeys(($event: any) => (jumpSlide()), ["enter"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.form.mobileLabel")), 1)
                  ]),
                  _: 1
                }, 8, ["placeholder", "type", "countries", "onChange"]),
                (validity.value.mobile)
                  ? (_openBlock(), _createBlock(_unref(NTypeformCta), {
                      key: 0,
                      "data-cy": "cta-next",
                      "background-color": _unref(ui).colors.primary,
                      onClick: _cache[21] || (_cache[21] = ($event: any) => (jumpSlide()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("ok")), 1)
                      ]),
                      _: 1
                    }, 8, ["background-color"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(NTypeformSlide), { index: 9 }, {
            default: _withCtx(() => [
              _createVNode(_unref(NCheckbox), {
                id: "input-terms",
                "data-cy": "input-terms",
                name: "terms",
                "border-color": _unref(ui).colors.primary,
                "error-color": _unref(ui).colors.error,
                onInput: _cache[22] || (_cache[22] = ($event: any) => (updateFieldValidity('terms')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("register.form.terms")), 1)
                ]),
                _: 1
              }, 8, ["border-color", "error-color"]),
              _createVNode(_unref(NCheckbox), {
                id: "input-optin",
                "data-cy": "input-optin",
                name: "emailOptin",
                "border-color": _unref(ui).colors.primary,
                "error-color": _unref(ui).colors.error
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.$t('register.form.optinLabel')
                  }, null, 8, _hoisted_9)
                ]),
                _: 1
              }, 8, ["border-color", "error-color"]),
              _createVNode(_unref(NCheckbox), {
                id: "input-mobile-optin",
                "data-cy": "input-mobile-optin",
                name: "smsOptin",
                "border-color": _unref(ui).colors.primary,
                "error-color": _unref(ui).colors.error
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.$t('register.form.mobileOptinLabel')
                  }, null, 8, _hoisted_10)
                ]),
                _: 1
              }, 8, ["border-color", "error-color"]),
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_unref(NCtaLoader), {
                  "background-color": {
                  default: _unref(ui).colors.primary,
                  hover: 'white',
                  error: _unref(ui).colors.error,
                  success: _unref(ui).colors.primary,
                },
                  "text-color": { default: 'white', hover: _unref(ui).colors.primary },
                  "border-color": { default: 'transparent', hover: 'transparent' },
                  disabled: false,
                  "border-radius": "24px",
                  state: registerLoadingState.value,
                  "data-cy": "submit-register",
                  onClick: ($event: any) => (handleSubmit(handleSubmitForm)),
                  onFinishedAnimation: handleFinishedAnimation,
                  onKeyup: _cache[23] || (_cache[23] = _withKeys(($event: any) => (jumpSlide()), ["enter"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.form.submit")), 1)
                  ]),
                  _: 2
                }, 1032, ["background-color", "text-color", "state", "onClick"])
              ])
            ]),
            _: 2
          }, 1024)
        ]),
        navigation: _withCtx(() => [
          _createVNode(_unref(NTypeformNavigation), {
            ref: "nTypeFormRef",
            "data-cy": "typeform-complete",
            "current-slide": currentSlide.value,
            "required-slides": _unref(requiredSlides).length,
            "total-slides": totalSlidesCount,
            "completed-slides": completedSlidesCount.value,
            "next-enabled": validity.value[slides[currentSlide.value]],
            "background-color": _unref(ui).colors.primary,
            onNext: nextSlide,
            onPrev: prevSlide
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("register.typeform.complete")), 1)
            ]),
            _: 1
          }, 8, ["current-slide", "required-slides", "completed-slides", "next-enabled", "background-color"])
        ]),
        _: 2
      }, 1032, ["current-slide"]),
      _cache[33] || (_cache[33] = _createElementVNode("div", { class: "flex items-center justify-center" }, null, -1))
    ]),
    _: 1
  }, 8, ["validation-schema", "initial-values"]))
}
}

})